<template>
	<div class="w-full h-full flex items-center justify-center" >
		<i class="fa-solid fa-spinner fa-spin"></i>
    <div v-if="show_home" class="absolute top-0 left-0 w-full h-full bg-white bg-opacity-90 flex items-center justify-center">
      <a href="/" class="text-2xl text-blue-500">Shko tek Kreu</a>
    </div>
	</div>
</template>

<script>
  // sends to home if the page stays here more than 3 seconds, 
  // otherwise cancel the timeout if the page is closed before 3 seconds 
  export default {
    name: 'LoadingPage',
    data() {
      return {
        timeout: null, 
        show_home: false
      }
    },
    mounted() {
      // create a timeout to send to home that can be cancelled  
      this.timeout = setTimeout(() => {
        this.show_home = true
      }, 5000)
    },
    beforeUnmount() {
      // cancel the timeout if the page is closed before 3 seconds 
      clearTimeout(this.timeout)
    }, 
  }
</script>

<style scoped>

</style>
